.bottom {
  width: 1px;
  height: 1px;
}

:global(.hairlines) .bottom {
  width: 0.5px;
  height: 0.5px;
}

.clearfix:after {
  content: '.';
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}

.space10 {
  height: 0.714286rem;
  overflow: hidden;
}

.space20 {
  height: 1.428571rem;
  overflow: hidden;
}

.space28 {
  height: 2rem;
  overflow: hidden;
}

.space30 {
  height: 2.142857rem;
  overflow: hidden;
}

.space40 {
  height: 2.857143rem;
  overflow: hidden;
}

.col_left {
  width: 75rem;
  float: left;
}

.col_right {
  width: 21.428571rem;
  float: right;
}

.col_right > div:not(:first-child) {
  margin-top: 1.428571rem;
}

.foot {
  width: 100%;
  padding-top: 0.142857rem;
}

.foot h4 {
  font-family: PingFangSC-Regular, PingFang SC !important;
}

.col_w1000 {
  width: 100rem;
  margin: 0 auto;
}

.ad_box {
  width: 21.428571rem;
  height: 17.857143rem;
}

.col_title {
  background: url(http://y1.ifengimg.com/a/2014/1208/ent/y_16.jpg) left bottom no-repeat;
  padding-bottom: 0.785714rem;
  line-height: 2.142857rem;
  margin-bottom: 1.071429rem;
}

.col_w1000 .col_title a {
  font-size: 1.857143rem;
  color: #2b2b2b;
  font-family: Microsoft Yahei;
  text-decoration: none;
}

.banner_box {
  width: 100rem;
  margin: 0 auto;
  margin-bottom: 2.142857rem;
  margin-top: 2.142857rem;
  height: 6.428571rem;
}

.ad_float_1 {
  z-index: 2147483645;
  position: fixed;
  float: left;
  display: block;
  top: 7.142857rem;
  left: 1px;
  width: 8.571429rem;
  height: 22.5rem;
}

:global(.hairlines) .ad_float_1 {
  left: 0.5px;
}

.ad_banner {
  width: 75rem;
  height: 7.857143rem;
}

.ad_float_2 {
  z-index: 2147483645;
  position: fixed;
  display: block;
  top: 7.142857rem;
  right: 1px;
  width: 8.571429rem;
  height: 22.5rem;
}

:global(.hairlines) .ad_float_2 {
  right: 0.5px;
}

.channel {
  margin: 2.357143rem 0;
  width: 100%;
  overflow: hidden;
}

.channel .channel_left {
  width: 35.714286rem;
  float: left;
}

.channel .channel_right {
  width: 35.714286rem;
  float: right;
}

.ip_col {
  font-family: PingFangSC-Regular, PingFang SC;
}

a:hover {
  text-decoration: none;
}