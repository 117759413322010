.right_article {
  width: 100%;
  margin-top: 0.714286rem;
}

.right_article .first_item {
  cursor: pointer;
  position: relative;
  width: 100%;
  height: 12.071429rem;
  display: inline-block;
}

.right_article .first_item img {
  width: 100%;
}

.right_article .first_item p {
  position: absolute;
  overflow: hidden;
  background: #000;
  background: -webkit-gradient(linear, left top, left bottom, from(transparent), to(rgba(0, 0, 0, .7)));
  background: -webkit-linear-gradient(top, transparent, rgba(0, 0, 0, .7));
  background: linear-gradient(180deg, transparent, rgba(0, 0, 0, .7));
  text-overflow: ellipsis;
  width: 20rem;
  color: #fff;
  font-size: 1.142857rem;
  font-weight: 500;
  line-height: 1.571429rem;
  height: 1.571429rem;
  white-space: nowrap;
  padding: 0.714286rem;
  bottom: 0;
  left: 0;
}

.right_article .item {
  display: block;
  cursor: pointer;
  color: #212223;
  font-size: 1.142857rem;
  line-height: 1.571429rem;
  margin-top: 1.142857rem;
}

.right_article .item:hover {
  text-decoration: none;
  color: #f54343;
}

.right_article .check_more {
  width: 100%;
  margin-top: 0.714286rem;
  display: block;
  cursor: pointer;
  height: 2.571429rem;
  background-color: #f0f0f0;
  text-align: center;
}

.right_article .check_more span {
  font-size: 1rem;
  color: #84848a;
  line-height: 2.571429rem;
}

.right_article .check_more:hover {
  text-decoration: none;
}

.right_article .check_more:hover span {
  color: #f54343;
}

.assess {
  width: 100%;
  margin-bottom: 1.428571rem;
}