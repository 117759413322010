.pagination {
  margin: 2.571429rem 0;
  width: 100%;
  text-align: center;
}

.button,
.button1 {
  font-size: 1rem;
  color: #84848a;
  padding: 0.571429rem 0.857143rem;
  background-color: #f0f0f0;
  border-radius: 0.428571rem;
  margin-right: 0.857143rem;
  cursor: pointer;
}

.button span,
.button1 span {
  vertical-align: -1px;
}

:global(.hairlines) .button span,
:global(.hairlines) .button1 span {
  vertical-align: -0.5px;
}

.button1 {
  cursor: not-allowed;
}

.page_button {
  font-size: 1rem;
  color: #84848a;
  display: inline-block;
  width: 2.571429rem;
  height: 2.571429rem;
  line-height: 2.571429rem;
  text-align: center;
  background-color: #f0f0f0;
  border-radius: 0.428571rem;
  margin-right: 0.857143rem;
  cursor: pointer;
}

.button:hover,
.normal:hover {
  color: #f54343;
}

.active {
  background-color: #f54343;
  color: #fff;
}