.hotEvent {
  margin-top: -0.642857rem;
}

.hotEvent .newsflow {
  margin-top: 0.142857rem;
}

.hotEvent .newsflow .main_title {
  padding-left: 1.142857rem;
  background: url(./img/event.png) no-repeat left center;
  background-size: 0.857143rem;
  color: #212223;
  height: 2.714286rem;
  line-height: 2.714286rem;
  font-size: 1.142857rem;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  position: relative;
}

.hotEvent .newsflow .main_title img:last-child {
  position: absolute;
  right: 0;
  top: 0.928571rem;
}

.hotEvent .newsflow .main_title span {
  display: inline-block;
  max-width: 95%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.hotEvent .newsflow:not(:last-child) {
  border-bottom: 0.214286rem solid #f7f7f7;
}

.hotEvent .newsflow:not(:last-child) .content {
  padding: 0.714286rem 0 0.928571rem;
}

.hotEvent .newsflow:last-child .content {
  padding-top: 0.714286rem;
}

.hotEvent .content {
  height: 4.785714rem;
  border-top: 1px solid #eeeeee;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  color: #212223 !important;
}

:global(.hairlines) .hotEvent .content {
  border-top: 0.5px solid #eeeeee;
}

.hotEvent .content .img_content,
.hotEvent .content .img_content1 {
  width: 8.571429rem;
  height: 100%;
}

.hotEvent .content .img_content img {
  width: 100%;
  height: 100%;
}

.hotEvent .content .img_content1 {
  background-color: #e2e2e2;
  position: relative;
}

.hotEvent .content .img_content1 img {
  width: 90%;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.hotEvent .content .text_content {
  width: 12.142857rem;
  font-size: 1.142857rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  line-height: 1.571429rem;
}

.hotEvent .more {
  display: inline-block;
  height: 2.571429rem;
  width: 100%;
  line-height: 2.571429rem;
  text-align: center;
  background: #f0f0f0;
  font-size: 1rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #84848a !important;
  margin-top: 0.714286rem;
  cursor: pointer;
}

.hotEvent .more:hover,
.hotEvent .content:hover {
  color: #f54343 !important;
}

.hotEvent .main_title:hover {
  background-image: url(./img/redEvent.png);
  color: #f54343 !important;
}