.pic_container {
  width: 100%;
  overflow: hidden;
}

.pic_container .two_pic {
  width: 24.285714rem;
  overflow: hidden;
  float: right;
}

.fpic06 {
  width: 50rem;
  height: 28.142857rem;
  float: left;
  position: relative;
  overflow: hidden;
}

.fpic06 .text,
.bigPic05 .text {
  width: 50rem;
  height: 4.285714rem;
  font-size: 1.142857rem;
  background: #000;
  left: 0;
  filter: alpha(opacity=75);
  opacity: 0.75;
  position: absolute;
  bottom: 0;
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0)), to(rgba(0, 0, 0, .7)));
  background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, .7) 100%);
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, .7) 100%);
  filter: alpha(opacity=70);
}

.fpic06 [ifeng_ui_pc_sliders-dots] {
  position: absolute;
  left: 0;
  bottom: 0.571429rem;
  height: 0.285714rem;
  line-height: 0.285714rem;
  text-align: center;
}

.fpic06 p,
.bigPic05 p {
  font-size: 1.142857rem;
  width: 50rem;
  height: 4.285714rem;
  line-height: 4.285714rem;
  position: absolute;
  bottom: 0;
  left: 0;
  padding-left: 0.714286rem;
}

.bigPic05:last-child {
  margin-top: 0.714286rem;
}

.bigPic05 {
  height: 13.714286rem;
  overflow: hidden;
}

.bigPic05 img {
  width: 100%;
  height: 100%;
}

.bigPic05 .text {
  width: 24.285714rem;
  height: 3rem;
}

.bigPic05 p {
  width: 24.285714rem;
  height: 3rem;
  line-height: 3rem;
}

.bigPic05 .cWhite a {
  display: inline-block;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 23.571429rem;
  height: 13.714286rem;
}

.bigPic04Wrap {
  width: 50rem;
  height: 28.142857rem;
}

.bigPic04Wrap a {
  width: 100%;
}

.bigPic04,
.bigPic05 {
  position: relative;
}

.bigPic04 a img,
.bigPic05 a img {
  display: block;
}

#prevSlide02,
#nextSlide02 {
  width: 2.571429rem;
  height: 4.285714rem;
  position: absolute;
  top: 5rem;
  left: 0;
  z-index: 10;
  text-align: center;
  cursor: pointer;
}

#prevSlide02 img,
#nextSlide02 img {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 12;
}

.btnBg {
  width: 2.571429rem;
  height: 4.285714rem;
  filter: alpha(opacity=60);
  -moz-opacity: 0.6;
  opacity: 0.6;
  background: #000;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 11;
  display: none;
}

.btnBgHover {
  width: 2.571429rem;
  height: 4.285714rem;
  filter: alpha(opacity=100);
  -moz-opacity: 1;
  opacity: 1;
  background: #000;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 11;
  display: none;
}

#nextSlide02 {
  left: 26.071429rem;
}

#nextSlide02 img {
  left: 0;
}

/* .dotList04 {
    position: absolute;
    right: 10px;
    top: 162px;
    height: 9px;
    overflow: hidden;
    width: 88px;
}

.fpic06 [ifeng_ui_pc_sliders-dots-item] {
    width: 20px;
    height: 2px;
    margin: 0 0 0 2px;
    cursor: pointer;
    background: #d3cecb;
    overflow: hidden;
}

.fpic06 [ifeng_ui_pc_sliders-dots-item='current'] {
    background: #f54343;
} */

.cWhite a,
.cWhite a:visited,
.cWhite a:hover {
  color: #fff;
}

/* .fpic06 [ifeng_ui_pc_sliders-dots] div {
    width: 20px;
    height: 5px;
}
.current {
    background: #ff2838 !important;
} */

.trans {
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  -webkit-backface-visibility: hidden;
}

.trans:hover {
  transform: scale(1.04, 1.04);
  -ms-transform: scale(1.04, 1.04);
  -webkit-transform: scale(1.04, 1.04);
}

.left_box {
  float: left;
  margin: 1.857143rem 2.857143rem 0 0;
  width: 34.285714rem;
}

.dot {
  margin-left: 0.285714rem;
  margin-right: 0;
  width: 0.285714rem !important;
  height: 0.285714rem !important;
  background: #ffffff;
  border-radius: 0.142857rem;
  opacity: 0.5;
}

.dot:first-child {
  margin-left: 0;
}

.current {
  margin-left: 0.285714rem;
  margin-right: 0;
  height: 0.285714rem !important;
  width: 0.857143rem !important;
  opacity: 1;
  background: #ffffff !important;
  border-radius: 0.142857rem;
  opacity: 0.8;
}

.current:first-child {
  margin-left: 0;
}