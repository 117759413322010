.clearfix:after {
  content: '.';
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}

.infoConOneBox {
  padding-left: 0.714286rem;
  padding-right: 0.714286rem;
  margin-left: -0.714286rem;
  margin-top: 1.142857rem;
  width: 46.428571rem;
}

.infoConOneTxt {
  border-bottom: 1px solid #e8e8e8;
  padding-bottom: 1.285714rem;
  position: relative;
}

:global(.hairlines) .infoConOneTxt {
  border-bottom: 0.5px solid #e8e8e8;
}

.infoConOneBox_pic {
  width: 12rem;
  height: 6.428571rem;
  float: left;
  margin-right: 1.428571rem;
}

.infoConOneBox h3 {
  height: 4.285714rem;
  line-height: 2.142857rem;
  overflow: hidden;
  padding-top: 1.071429rem;
}

.vBlack {
  background: url(http://p0.ifengimg.com/fe/ent_ifeng_com/images/vGray_8ac3eab5.gif) no-repeat left center;
  padding-left: 1.428571rem;
}

.vPoz {
  background-position: 0 1.571429rem;
}

.pl {
  position: absolute;
  width: 3.214286rem;
  height: 1rem;
  right: 0;
  bottom: 0.642857rem;
}

.infoConOneBox_pic a img {
  display: block;
  width: 11.428571rem;
  height: 6.428571rem;
}

.infoConOneBox h3 a {
  font-size: 1.285714rem;
  color: #000;
  font-weight: normal;
  font-family: Microsoft Yahei;
}

.pl a {
  background: url(http://y0.ifengimg.com/fe/zx1/images/ly_a9ebd58d.jpg) no-repeat 0 0.142857rem;
  width: 1.785714rem;
  height: 1rem;
  display: block;
  color: #d1d1d1;
  overflow: hidden;
  padding-left: 1.285714rem;
  line-height: 1rem;
  text-decoration: none;
}

.top_wrap {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  width: 35.714286rem;
  padding-left: 1.642857rem;
  background: url(./top.png) no-repeat left center;
  background-size: 1.285714rem;
}

.top_title {
  margin-top: 2.857143rem;
}

.news {
  margin-top: 1.428571rem;
}

.line {
  width: 100%;
  overflow: hidden;
}

.line .left {
  float: left;
  width: 35.714286rem;
}

.line .right {
  float: right;
  width: 35.714286rem;
}

.line .left > div:last-child,
.line .right > div:last-child {
  margin-bottom: 0 !important;
}

.big_bottom {
  margin-bottom: 1.428571rem;
}

.tip {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  width: 21.428571rem;
  height: 2.571429rem;
  margin: 1.428571rem auto;
  background: rgba(245, 67, 67, .1);
  border-radius: 1.285714rem;
  cursor: pointer;
  font-size: 1rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #f54343;
  line-height: 2.571429rem;
  text-align: center;
}

.tip .text_tip {
  padding-left: 1.357143rem;
  background: url(./syncRed.png) no-repeat 0;
  background-size: 1rem;
}

.tip:hover {
  background: #f54343;
  color: white;
}

.tip:hover .text_tip {
  background: url(./sync.png) no-repeat 0;
  background-size: 1rem;
}