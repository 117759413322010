.channel_item {
  width: 100%;
  overflow: hidden;
}

.channel_item li {
  list-style: none;
}

.channel_item li div {
  width: 100%;
  height: 2.714286rem;
  line-height: 2.714286rem;
  overflow: hidden;
}

.channel_item li div a {
  font-size: 1.142857rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #212223;
}

.channel_item li div a:hover {
  color: #f54343;
  text-decoration: none;
}

.firat_channel_item {
  margin: 1.285714rem 0 0.714286rem 0;
  width: 100%;
  cursor: pointer;
  overflow: hidden;
}

.firat_channel_item .img,
.firat_channel_item .default_img {
  float: left;
  width: 14.142857rem;
  height: 7.857143rem;
  text-align: center;
}

.firat_channel_item .img img {
  width: 100%;
}

.firat_channel_item .default_img {
  background-color: #e2e2e2;
  position: relative;
}

.firat_channel_item .default_img img {
  width: 70%;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.firat_channel_item .article_info {
  width: 20.714286rem;
  float: right;
  height: 7.857143rem;
  position: relative;
}

.firat_channel_item .article_info .title {
  font-size: 1.142857rem;
  color: #212223;
  line-height: 1.571429rem;
  font-weight: 600;
  width: 100%;
}

.firat_channel_item .article_info .title:hover {
  color: #f54343;
}

.firat_channel_item .article_info .date {
  position: absolute;
  bottom: 0;
  left: 0;
  font-size: 12p;
  line-height: 1.214286rem;
  color: #84848a;
  cursor: auto;
  cursor: initial;
}

.firat_channel_item:hover .title {
  color: #f54343;
}

.title:hover {
  text-decoration: none;
}