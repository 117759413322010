.tab_content {
  width: 100%;
  border-bottom: 1px solid rgba(245, 67, 67, .3);
  height: 2.785714rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
}

:global(.hairlines) .tab_content {
  border-bottom: 0.5px solid rgba(245, 67, 67, .3);
}

.tab_content a {
  color: inherit;
}

.tab_item {
  font-size: 1.285714rem;
  font-weight: 500;
  margin-right: 3.071429rem;
  line-height: 2.5rem;
  color: #84848a;
  padding: 0.642857rem 0;
  cursor: pointer;
}

.tab_item_small {
  margin-right: 2.428571rem;
}

.active {
  font-size: 1.428571rem;
  padding-bottom: 0.357143rem;
  color: #212223;
  border-bottom: 0.285714rem solid #f54343;
}

.normal:hover,
.more:hover {
  color: #f54343;
}

.more {
  float: right;
  overflow: hidden;
  font-size: 1rem;
  font-weight: 400;
  color: #666;
  cursor: pointer;
  line-height: 3rem;
}

.more img {
  width: 0.571429rem;
  vertical-align: -0.357143rem;
  margin-left: 0.357143rem;
}