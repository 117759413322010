.tech_wrap {
  margin-top: 2.357143rem;
}

.techVideo {
  width: 100%;
  overflow: hidden;
  margin-bottom: 2.5rem;
}

.techVideo .video_item:hover .video_title {
  color: #f54343;
}

.techVideo .video_item {
  float: left;
  width: 17.785714rem;
  margin: 1.285714rem 1.285714rem 0.714286rem 0;
}

.techVideo .video_item .video_img {
  display: inline-block;
  position: relative;
  cursor: pointer;
  margin-bottom: 0.714286rem;
}

.techVideo .video_item .video_img .play_icon {
  width: 2.857143rem;
  height: 2.857143rem;
  position: absolute;
  left: 50%;
  margin-left: -1.428571rem;
  top: 50%;
  margin-top: -1.428571rem;
}

.techVideo .video_item .video_img > img,
.techVideo .video_item .video_img > div {
  height: 10rem;
}

.techVideo .video_item .video_img > div > img {
  width: 100%;
  line-height: 10.714286rem;
  -webkit-transform: translateY(50%);
  -ms-transform: translateY(50%);
  transform: translateY(50%);
}

.techVideo .video_item .video_title {
  overflow: hidden;
  display: inline-block;
  height: 3.142857rem;
  cursor: pointer;
  color: #212223;
  font-size: 1.142857rem;
  line-height: 1.571429rem;
  margin-bottom: 0.642857rem;
}

.techVideo .video_item .video_title:hover {
  color: #f54343;
}

.techVideo .video_item .video_info {
  overflow: hidden;
}

.techVideo .video_item .video_source {
  float: left;
}

.techVideo .video_item .video_date {
  float: right;
}

.video_title:hover {
  text-decoration: none;
}

.video_source,
.video_date {
  font-size: 0.857143rem;
  color: #84848a;
  line-height: 1.214286rem;
}