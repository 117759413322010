.rank {
  display: inline-block;
  width: 21.428571rem;
  height: 1.571429rem;
  margin-top: 1rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.rank .index {
  display: inline-block;
  width: 0.928571rem;
  background-color: #c5c5c5;
  vertical-align: 10%;
  padding: 0.142857rem 0.357143rem;
  font-size: 0.857143rem;
  text-align: center;
  color: #fff;
  margin-right: 0.571429rem;
}

.rank .index_1 {
  background-color: #f54343;
}

.rank .index_2 {
  background-color: #f77b32;
}

.rank .index_3 {
  background-color: #f8c945;
}

.rank .title {
  color: #212223;
  font-size: 1.142857rem;
  line-height: 1.571429rem;
}

.rank:hover {
  text-decoration: none;
}

.rank:hover .title {
  color: #f54343;
}

.ranking_list {
  width: 100%;
  margin-top: 0.785714rem;
}