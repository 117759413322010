.logo {
  position: 'relative';
  width: 11.5rem;
  height: 1.928571rem;
}

.logo_2 {
  height: 1.785714rem;
  margin-left: 0.714286rem;
  margin-top: 0.142857rem;
}

.ad_1 {
  width: 12.714286rem;
  height: 1.285714rem;
  float: left;
  padding-top: 0.357143rem;
  padding-left: 0.642857rem;
}

.ad_2 {
  float: left;
  width: 13.214286rem;
  height: 1.785714rem;
  margin-left: 22.857143rem;
  padding-top: 0;
  padding-right: 0;
}