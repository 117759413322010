.news_item,
.news_top_item {
  height: 2.714286rem;
  overflow: hidden;
}

.news_item .title,
.news_top_item .title {
  font-weight: 400;
  float: left;
  font-size: 1.142857rem;
  line-height: 2.714286rem;
  color: #212223;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.news_item .title_blod,
.news_top_item .title_blod {
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
}

.news_item .title:hover,
.news_top_item .title:hover {
  text-decoration: none;
  color: #f54343;
}

.news_item .title:visited,
.news_top_item .title:visited {
  color: #84848a;
}

.news_item .date,
.news_top_item .date {
  width: 2.857143rem;
  text-align: center;
  font-size: 1rem;
  font-weight: 400;
  line-height: 2.714286rem;
  color: #84848a;
  float: right;
}

.news_item .jp,
.news_top_item .jp {
  font-weight: 600;
}

.news_top_item {
  /* width: 477px;*/
}

.news_top_item .title {
  width: 30.5rem;
}

.news_item {
  width: 35.714286rem;
}

.news_item .title {
  width: 32.142857rem;
}